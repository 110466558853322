import classNames from 'classnames';
import BeatLoader from 'components/animations/Loaders/BeatLoader';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import { HTMLAttributes } from 'react';
import { MapPropertyType } from 'types/property-types';
import { parseMoney } from 'utils/money_utils';
import classes from './index.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'property'> {
  property: MapPropertyType;
}

const FeedMapMarker = ({ property, className, ...props }: Props) => {
  const { [property?.ticker]: stockPrice } = useStockPrice(
    property?.ticker,
    false
  );

  return (
    <div
      className={classNames(classes.container, className)}
      data-tooltip-id="feed-map-tooltip"
      {...props}
    >
      {stockPrice ? (
        parseMoney(stockPrice)
      ) : (
        <BeatLoader
          className={classes.loader}
          color={'var(--color-black)'}
          size={7}
        />
      )}
    </div>
  );
};

export default FeedMapMarker;
