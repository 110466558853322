import Map from 'components/maps/Map';
import MapPropertyCard from 'components/properties/PropertyCard/MapPropertyCard';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import Image from 'next/image';
import FeedMapMarker from 'pages/feed/components/FeedMap/FeedMapMarker';
import React, { HTMLAttributes, useMemo } from 'react';
import { MapPropertyType } from 'types/property-types';

interface Props extends HTMLAttributes<HTMLDivElement> {
  fullPropertyView: boolean;
  mapProperties: MapPropertyType[];
  initialPoints?: [[number, number], [number, number]];
  feedMapIcon?: string;
}
const MARKER_SIZE = 64;
const MarkerIcon = (
  <Image
    src="/assets/icons/home_avatar.png"
    alt="home avatar"
    width={MARKER_SIZE}
    height={MARKER_SIZE}
  />
);

const FeedMap = ({
  mapProperties,
  fullPropertyView,
  initialPoints,
  ...props
}: Props) => {
  const renderPoint = useMemo(() => {
    return (property: MapPropertyType) => {
      return props?.feedMapIcon ? (
        MarkerIcon
      ) : (
        <FeedMapMarker property={property} />
      );
    };
  }, [fullPropertyView]);

  const tickers = mapProperties?.map((property) => property?.ticker) || [];
  useStockPrice(tickers);
  return (
    <Map<MapPropertyType>
      fullPropertyView={fullPropertyView}
      points={mapProperties || []}
      initialPoints={initialPoints}
      renderPoint={renderPoint}
      renderTooltip={(property) => <MapPropertyCard property={property} />}
      {...props}
    />
  );
};

export default FeedMap;
