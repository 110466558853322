import {
  DEFAULT_FLY_TO_OPTIONS,
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_CLUSTER_RADIUS,
  DEFAULT_MAP_MAX_BOUNDS,
  DEFAULT_MAP_OFFSET,
  DEFAULT_MAP_STYLE,
  DEFAULT_MAP_ZOOM,
  DEFAULT_MAX_ZOOM,
  PROPERTY_PAGE_MAX_ZOOM,
} from 'components/maps/consts';
import * as process from 'process';
import React, {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import ReactMapboxGl, { Cluster, Marker } from 'react-mapbox-gl';
import MapCluster from './components/MapCluster';
import classes from './index.module.scss';
import Popup from './components/CustomPopup';

export type MapPoint = {
  coordinates: {
    longitude: number;
    latitude: number;
  };
};

export type MapAttributes<T> = {
  zoom: [number];
  center: [number, number];
  chosenPoint?: T;
};

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  fullPropertyView: boolean;
  points: T[];
  renderPoint: (point: T) => ReactElement;
  renderTooltip?: (point: T) => ReactElement;
  initialPoints?: [[number, number], [number, number]];
}

const ReactMap = ReactMapboxGl({
  accessToken: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN as string,
  maxZoom: 15,
});

const Map = <T extends MapPoint>({
  fullPropertyView,
  points,
  renderPoint,
  renderTooltip,
  initialPoints,
}: Props<T>) => {
  console.log('dsadasdasdasdasdwqewqeqdad');
  const [mapState, setMapState] = useState<mapboxgl.Map>();
  const [mapAttrs, setMapAttrs] = useState<MapAttributes<T>>({
    zoom: DEFAULT_MAP_ZOOM,
    center: DEFAULT_MAP_CENTER,
    chosenPoint: undefined,
  });

  const onDrag = () =>
    setMapAttrs((prev) => ({ ...prev, chosenPoint: undefined }));

  const onMarkerClick = (point: T) => {
    setMapAttrs((prev) => ({
      ...prev,
      chosenPoint: point,
      center: [point.coordinates.longitude, point.coordinates.latitude],
    }));
  };

  const createCluster = (coordinates: number[], pointCount: number) => (
    <MapCluster
      key={coordinates[0].toString() + ',' + coordinates[1].toString()}
      className={classes.marker}
      coordinates={coordinates}
      pointCount={pointCount}
    />
  );

  useEffect(() => {
    if (initialPoints && mapState) {
      mapState?.scrollZoom?.setWheelZoomRate(1 / 200);
      const zoomLevel = fullPropertyView
        ? PROPERTY_PAGE_MAX_ZOOM
        : DEFAULT_MAX_ZOOM;
      mapState?.fitBounds(initialPoints, {
        maxZoom: zoomLevel,
      });
    }
  }, [initialPoints, mapState]);

  return (
    <ReactMap
      className={classes.container}
      zoom={mapAttrs.zoom}
      center={mapAttrs.center}
      maxBounds={DEFAULT_MAP_MAX_BOUNDS}
      style={DEFAULT_MAP_STYLE}
      renderChildrenInPortal={true}
      flyToOptions={DEFAULT_FLY_TO_OPTIONS}
      onDrag={onDrag}
      movingMethod={'easeTo'}
      onRender={(map) => {
        setMapState(map);
      }}
    >
      <>
        <Cluster
          radius={DEFAULT_MAP_CLUSTER_RADIUS}
          ClusterMarkerFactory={createCluster}
          zoomOnClick
        >
          {points?.map((point, key) => {
            return (
              <Marker
                className={classes.marker}
                key={key}
                onClick={() => onMarkerClick(point)}
                coordinates={[
                  point.coordinates.longitude,
                  point.coordinates.latitude,
                ]}
              >
                {renderPoint(point)}
              </Marker>
            );
          })}
        </Cluster>
        {mapAttrs?.chosenPoint && (
          <Popup
            className={classes.tooltip}
            key={
              mapAttrs?.chosenPoint?.coordinates?.latitude?.toString?.() +
              ',' +
              mapAttrs?.chosenPoint?.coordinates?.latitude.toString?.()
            }
            coordinates={[
              mapAttrs?.chosenPoint?.coordinates?.longitude,
              mapAttrs?.chosenPoint?.coordinates?.latitude,
            ]}
            offset={DEFAULT_MAP_OFFSET}
          >
            {renderTooltip && renderTooltip(mapAttrs?.chosenPoint)}
          </Popup>
        )}
      </>
    </ReactMap>
  );
};

export default Map;
