const POSITIVE_COLOR = '#00a688'; // var(--color-basil);
const NEGATIVE_COLOR = '#bc2d00'; // var(--color-rust)

export const getColorForValues = (values: number[]) => {
  const lastValue = values?.at?.(-1);
  if (lastValue && lastValue < values[0]) {
    return NEGATIVE_COLOR;
  }
  return POSITIVE_COLOR;
};

export const options = {
  responsive: false,
  scales: {
    x: { display: false },
    y: { display: false },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  legend: {
    display: false,
  },
};
