import classNames from 'classnames';
import Money from 'components/generics/texts/Money';
import Typography from 'components/generics/texts/Typography';
import PropertyOccupancyStatusLabel from 'components/properties/attributes/PropertyOccupancyStatus';
import { PROPERTY_TYPE_TO_TEXT } from 'components/properties/attributes/PropertyType/utils';
import PropertyTradeInformation from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyBottomSection/components/PropertyTradeInformation';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import { HTMLAttributes } from 'react';
import { PropertyType } from 'types/property-types';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  item: PropertyType;
}

const FeedPropertyBottomSection = ({
  item,
  item: { ticker, address, propertyType },
  className,
}: Props) => {
  const propertyTypeText = PROPERTY_TYPE_TO_TEXT[propertyType] || propertyType;
  const { [ticker]: stockPrice } = useStockPrice(ticker, false);

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.leftSection}>
        <Typography className={classNames(classes.title, classes.ellipsis)}>
          {address.houseNumber} {address.street}
        </Typography>
        <Typography className={classNames(classes.subtitle, classes.ellipsis)}>
          <Typography className={classes.ellipsis}>
            {propertyTypeText} {' ∙ '}
            <PropertyOccupancyStatusLabel
              className={classes.ellipsis}
              withIcon={false}
              property={item}
            />
          </Typography>
        </Typography>
      </div>
      <div className={classes.rightSection}>
        <Money
          withDecimal={true}
          className={classNames(classes.title, classes.stockPrice)}
          value={stockPrice}
        />
        <PropertyTradeInformation item={item} />
      </div>
    </div>
  );
};

export default FeedPropertyBottomSection;
