import FeedPropertyBottomSection from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyBottomSection';
import FeedPropertyCardLoader from 'components/properties/PropertyCard/FeedPropertyCard/components/FeedPropertyCardLoader';
import usePropertyMainPhoto from 'hooks/logics/usePropertyMainPhoto';
import Image from 'next/image';
import Link from 'next/link';
import { PropertyType } from 'types/property-types';
import {
  encodeTicker,
  propertyAddressPathGenerator,
} from 'utils/property_utils';
import classes from './index.module.scss';

interface Props {
  item: PropertyType;
}

export const PROPERTY_IMAGE_SIZE = 280;

const FeedPropertyCard = ({ item: { ticker, address }, item }: Props) => {
  const { photo } = usePropertyMainPhoto(item);
  const path = propertyAddressPathGenerator(address);

  return (
    <Link href={`/property/${path}/${encodeTicker(ticker)}`}>
      <div className={classes.propertyItem}>
        {photo && photo !== 'null' && photo !== 'TEST1' ? (
          <>
            <div className={classes.imageContainer}>
              <Image
                alt={ticker}
                src={photo}
                width={PROPERTY_IMAGE_SIZE}
                height={PROPERTY_IMAGE_SIZE}
                className={classes.propertyImage}
                priority
              />
            </div>
            <FeedPropertyBottomSection item={item} />
          </>
        ) : (
          <FeedPropertyCardLoader />
        )}
      </div>
    </Link>
  );
};

export default FeedPropertyCard;
